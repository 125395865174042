/* eslint-disable import/no-cycle */
import {executeGet} from '../requests';

const resourcesName = 'v1/reports';

export const ReportApi = {
  getResidencyFilter: (from, to) => executeGet(`${resourcesName}/residencyfilter`, {from, to}),
  getHourAnalysis: (from, to, facilityIds, areaIds, professionIds, residentFilter) =>
    executeGet(`${resourcesName}/hours/`, {from, to, facilityIds, areaIds, professionIds, residentFilter}),
  getBenchmarkAnalysis: (from, to, facilityIds, areaIds, professionIds, residentFilter) =>
    executeGet(`${resourcesName}/benchmarks/`, {from, to, facilityIds, areaIds, professionIds, residentFilter}),
  getRateAnalysis: (from, to, facilityIds, areaIds, residentFilter) =>
    executeGet(`${resourcesName}/rates/`, {from, to, facilityIds, areaIds, residentFilter}),
  getRai: (from, to, facilityIds, areaIds, residentFilter) =>
    executeGet(`${resourcesName}/rai/`, {from, to, facilityIds, areaIds, residentFilter}),
  getMinutesAnalysis: (from, to, facilityIds, areaIds, professionIds, residentFilter) =>
    executeGet(`${resourcesName}/minutes/`, {from, to, facilityIds, areaIds, professionIds, residentFilter}),
};
