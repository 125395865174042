import moment from 'moment/moment';

const formatTime = (value, unit, format, excludeUnits = false) => {
  const duration = moment.duration(value, unit);
  const d = Math.floor(duration.asDays());
  const H = Math.floor(duration.asHours() % 24);
  const m = Math.floor(duration.asMinutes() % 60);
  const z = (n) => (n < 10 ? '0' : '') + n;

  if (excludeUnits) {
    return format === 'HH mm' ? `${d ? `${d}d ` : ''}${z(H)}:${z(m)}` : `${z(m)}`;
  }
  return format === 'HH mm' ? `${d ? `${d}d ` : ''}${z(H)}h:${z(m)}m` : `${z(m)}m`;
};

export const humanizeSeconds = (secs) => formatTime(secs, 'seconds', 'HH mm');

export const humanizeMinutes = (minutes, format = 'HH mm', excludeUnits = true) =>
  formatTime(minutes, 'minutes', format, excludeUnits);

export const computerizeMinutes = (stringInput) => {
  const splittedInput = stringInput.split(':');
  return parseInt(splittedInput[0], 10) * 60 + parseInt(splittedInput[1], 10);
};

export const humanizeSecondsHMS = (secs) => moment.utc(secs * 1000)
  .format(secs >= 3600 ? 'H[h]:mm[m]:ss[s]' : 'm[m]:ss[s]');

export const humanizeHoursDHM = (hours) => formatTime(hours, 'hours', 'HH mm');
